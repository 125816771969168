import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveCB, resolveAboveCta } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query TransmitterQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "transmittersPage", uri: $uri, siteId: $siteId) {
        ... on Craft_transmittersPage_transmitter_Entry {
          title
          url
          summary
          # metaFeatured
          #Hero
          heroTitle
          entrySubtitle
          embedCode
          #Sections
          sections {
            ...SectionFragment
          }
          # Resources
          heading1
          subHeading1
          resourcesPdfList {
            ... on Craft_resourcesPdfList_pdfLink_BlockType {
              anchorLink {
                text
                url
              }
            }
          }
          resourcesVideoList {
            ... on Craft_resourcesVideoList_videoLink_BlockType {
              anchorLink {
                text
                url
              }
            }
          }
          resourcesArticlesList {
            ... on Craft_caseStudies_caseStudy_Entry {
              url
              title
              heroTitle
              articleCategory {
                ... on Craft_blog_Category {
                  title
                }
              }
            }
          }
          link5 {
            url
            text
          }
          # Dealership
          heading
          subHeading
          link1 {
            text
            url
          }
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  if (!entry) return null;
  const {
    title,
    // Hero
    heroTitle,
    entrySubtitle,
    embedCode,
    sections,
    // Resources
    heading1,
    subHeading1,
    resourcesPdfList,
    resourcesVideoList,
    resourcesArticlesList,
    link5,
    // CTA
    heading,
    subHeading,
    link1,
    // Abover Footer
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
      embedCode,
      featured: !!embedCode?.length,
      headingBelow: !!embedCode?.length,
    },
    sections: resolveCB(sections),
    resources: {
      heading: heading1,
      subheading: subHeading1,
      pdfs: resourcesPdfList?.map(pdf => pdf?.anchorLink),
      videos: resourcesVideoList?.map(video => video?.anchorLink),
      articles: resourcesArticlesList?.map(article => {
        return {
          ...article,
          category: article?.articleCategory?.[0]?.title,
        };
      }),
      link: link5,
    },
    findADealer: {
      heading,
      summary: subHeading,
      link: link1,
    },
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
